.ssrsportfolio .k-popup .k-item
{
  white-space: pre;  
  font: 12px "courier new";
}

.ssrsportfolio .k-popup
{
  white-space: pre;  
  font: 12px "courier new";
}