.widgetHeight1 { height: 440px;}
.widgetHeight2 { height: 700px;}
.dragContainer > div { width: 100%; }
.d-none { display: none; }

/* width */
::-webkit-scrollbar {
  width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f18c; 
  border-radius: 25px;
}
   
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8888888c; 
  border-radius: 25px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5555558c; 
  border-radius: 25px;
}


.widgetCardColumn4, .snapshotColumn4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.widgetCardColumn6, .snapshotColumn6 {
    flex: 0 0 100%;
    max-width: 100vw;
}

.acctOverviewTopHoldings {
    visibility: hidden;
}

.liteSnapshot {
 
    position: relative;
    bottom: 2%;
    left: 0;
    height: 100%;
    width: 98vw;
    overflow-y: scroll;
}


.liteSnapshotAlt {
 
    position: relative;
    bottom: 2%;
    left: 0;
    height: 100%;
    width: 98vw;
    overflow-y: scroll;
}


.templateTable {
    padding-left: 5;
    float: left;
    padding-bottom: 20px;
    font-family: 'Muli';
    font-weight: bold;
}

.templateTable2 {
    padding-left: 5;
    float: left
}

.tdPaddingRight td {
    padding: 0.2vw;
    padding-right: 3.2vw;
    font-size: 50%;

}

.tdPaddingRight2 td {
    padding: 0.2vw;
    padding-left: 2vh;
    font-size: 50%;
}

.tdPaddingRight img {
    width: 35px;
    height: 35px;
}

.tdPaddingRight2 img {
    width: 35px;
    height: 35px;
}

.liteSnapshotHouseholdDropDown .k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    width: 85vw;
}

.template2Image {
    display: block;
    width: 100%;
    height: auto;
}

.template2ImageTable {
    float: left;
    width:50vw;
    max-width: 250px
}

.widgetCardColumn12, .snapshotColumn12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.templateTable th > label {
    font-size: 60%;
}

.templateTable2 th > label {
    font-size: 60%;
}

.template2Header {
    padding-left: 5;
}

.template2ImageHeader {
    padding-left: 5;
    padding-top: 7px;
    font-size: 60%;
}

.snapshotHouseholdDropDown {
    padding: 10px 10px 5px;
}

.liteSnapshotHouseholdDropDown {
    padding: 40px 10px 5px;
    position: relative;
    /* bottom: 38vw; */
    /* top: 65vw; */
    left: 0;
    height: 40vh;
    width: 80vw;
}





@media screen and (min-width: 700px) {
    .tdPaddingRight td {
        padding: 0.2vw;
        padding-right: 3.2vw;
        font-size: 100%;
    }

    .tdPaddingRight2 td {
        padding: 0.2vw;
        padding-left: 2vh;
        font-size: 100%;
    }

    .tdPaddingRight img {
        width: 50px;
        height: 50px;
    }
    
    .tdPaddingRight2 img {
        width: 50px;
        height: 50px;
    }

    .template2ImageTable {
        float: left;
        width:50vw;
        max-width: 500px
    }

    .templateTable {
        padding-left: 6.4vh;
        float: left
    }

    .templateTable th > label{
        font-size: 100%;
    }

    .templateTable2 {
        /* padding-left: 6.4vh; */
        float: left
    }

    .templateTable2 th > label{
        font-size: 100%;
    }

    .template2Header {
        padding-left: 60;
    }

    .templateTable th > label{
        font-size: 100%;
    }

    .templateTable2 th > label{
        font-size: 100%;
    }

    .template2ImageHeader {
        padding-left: 5;
        padding-top: 0px;
        font-size: 100%;
    }

    .liteSnapshot {
        position: relative;
        bottom: 2%;
        left: 0;
        height: 100%;
        width: 60vw;
        min-width: 1100px;
        overflow-y: scroll;
    }
    
    .liteSnapshotAlt {
        position: relative;
        bottom: 2%;
        left: 0;
        height: 100%;
        width: 60vw;
        min-width: 1100px;
        overflow-y: scroll;
    }

    .liteSnapshotHouseholdDropDown {
        padding: 40px 10px 5px;
        position: relative;
        /* bottom: 10%; */
        /* top: 18vw; */
        left: 0;
        height: 50vh;
        width: 80vw;
    }

    .liteSnapshotHouseholdDropDown .k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
        width: 65%;
        min-width: 500px;
    }

    .widgetCardColumn6, .snapshotColumn6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .acctOverviewTopHoldings {
        visibility: visible;
    }
    
 }



.widgetRow, .snapshotRow {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px; */
    /* margin-left: -15px; */
    margin-top: 4px;
    margin-bottom: 4px;
}

.widgetCardColumn, .snapshotColumn {
    /* outline: 3px solid #aaa; */
    margin: 0;
    position: relative;
    width: 100%;
    /* padding-right: 15px;
    padding-left: 15px; */
}

.widgetCard {
    border: 2px solid #efefef;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 10px;
    /* padding: 10px; */
}
.widgetHeader {
    background-color: #efefef;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-items:center;
    vertical-align: middle;
    border-radius: 10px 10px 0 0;
    cursor: move;
}
.widgetTitle {
    font-weight: bold;
    font-size: 18px;
    align-items: center;
}
.widgetDragIcon {
    color: #888;
}
.loadingIcon {
    margin: 100px auto 0;
    display: flex;
}

.absoluteDragIcon {
    position: absolute;
    right: 20px;
    top: 25px;
    color: #888;
}

.widgetBody {
    margin: 10px;
    cursor: pointer;
}

.widgetBody label {
    cursor: pointer;
}

.widgetNoData {
    text-align: center;
    padding-top: 150px;
    padding-bottom: 200px;
}

.widgetNoDataWithDate {
    text-align: center;
    padding-top: 106.51px;
    padding-bottom: 200px;
}


.snapshotList {
    list-style: none;
}
.snapshotList li {
    position: relative;
    display: inline-block;
    margin-right: 20px;
}

.w-100 {
    width: 100%!important;
}

.d-flex {
    display: flex !important;
}
.flex-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-shrink-1 {
    flex-shrink: 1 !important;
}
.flex-grow-0 {
    flex-grow: 0 !important;
}
.flex-grow-1 {
    flex-grow: 1 !important;
}
.justify-content-center {
    justify-content: center !important;
}
.widthButton {
    margin: 0 2px;
}
.k-button-solid-primary {
    border-color: #1274AC;
    color: white;
    background-color: #1274AC;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}